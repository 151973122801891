
export const nationalFlags = {
    "Catar": "qa",
    "Equador": "ec",
    "Inglaterra": "gb-eng",
    "Irão": "ir",
    "Senegal": "sn",
    "Países Baixos": "nl",
    "Estados Unidos": "us",
    "País de Gales": "gb-wls",
    "Argentina": "ar",
    "Arábia Saudita": "sa",
    "Dinamarca": "dk",
    "Tunísia": "tn",
    "México": "mx",
    "Polónia": "pl",
    "França": "fr",
    "Austrália": "au",
    "Marrocos": "ma",
    "Croácia": "hr",
    "Alemanha": "de",
    "Japão": "jp",
    "Espanha": "es",
    "Costa Rica": "cr",
    "Bélgica": "be",
    "Canadá": "ca",
    "Suíça": "ch",
    "Camarões": "cm",
    "Uruguai": "uy",
    "República da Coreia": "kr",
    "Portugal": "pt",
    "Gana": "gh",
    "Brasil": "br",
    "Sérvia": "rs"
};